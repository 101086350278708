export function video(currentShow) {
  if(currentShow.videoVisible) {
    document.querySelector('.video__image-container').innerHTML = `<a data-fancybox href="${currentShow.video.url}" class="video__link" onclick="gtag('event', 'click', {'event_category': 'video', 'event_label': 'play_video'});"><img class="lazyload video__image" data-src="https://secure.espncdn.com/promotions/bsa/2023/apps/cgd/assets/img/video/${currentShow.video.image}" alt=""><img class="lazyload video__play-btn" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/play-btn.png" alt=""></a>`;

    document.querySelector('.video__heading').innerHTML = currentShow.video.headline;

    const videoLaunchTime = dayjs.tz(currentShow.video.launchTime, "America/New_York");

    let videoLaunched = false;

    const videoTimer = () => {
      const currTime = dayjs();

      if(currTime >= videoLaunchTime) {
        if(!videoLaunched) {
          videoLaunched = true;
          document.querySelector('.video').classList.remove('video--hide');
          clearInterval(videoInterval);
        }
      }
    };

    const videoInterval = setInterval(videoTimer, 15000);
    videoTimer();
  } else {
    document.querySelector('.video').classList.add('video--hide');
  }
}
