export function promo(currentShow) {
  if(currentShow.promoVisible) {
    const promoStartTime = dayjs.tz(currentShow.promo.startTime, "America/New_York"),
          promoEndTime = dayjs.tz(currentShow.promo.endTime, "America/New_York"),
          dayBefore = dayjs.tz(currentShow.promo.startTime, "America/New_York").subtract(1, "day");

      let promoSuffix = '',
          newPromoSuffix = '',
          promoSize = '',
          newPromoSize = '',
          promoLaunched = false;

      const onImageResize = () => {
        const imgWidth = document.querySelector('.promo__image').clientWidth;

        if(imgWidth >= 971) {
          newPromoSize = '1280x100';
        } else if(imgWidth >= 729) {
          newPromoSize = '970x100';
        } else if(imgWidth >= 640) {
          newPromoSize = '728x90';
        } else {
          newPromoSize = '300x250';
        }

        promoTimer();
      }
      const promoTimer = () => {
        const currTime = dayjs();

        let promoURL = currentShow.promo.preURL;

        if (currTime.isBefore(promoStartTime)) {
          if (promoStartTime.diff(currTime, "days") <= 6 && promoStartTime.diff(currTime, "days") > 1 && currTime.format("dddd") !== promoStartTime.format("dddd")) {
            //Week of the game
            newPromoSuffix = currentShow.promo.weekOfGame;
          } else if (promoStartTime.diff(currTime, "days") <= 1) {
            //Day before/of the game
            if (currTime.format("dddd") === dayBefore.format("dddd")) {
              //Tomorrow message
              newPromoSuffix = (currTime.hour() >= 6) ? currentShow.promo.dayBefore : currentShow.promo.weekOfGame;
            } else if (promoStartTime.format("MM-DD-YYYY") === currTime.format("MM-DD-YYYY")) {
              //Today/Tonight message
              newPromoSuffix = currentShow.promo.dayOfGame.beforeGame;
            } else {
              //Default message
              newPromoSuffix = currentShow.promo.weekOfGame;
            }
          } else {
            //Default message
            newPromoSuffix = currentShow.promo.default;
          }
        } else if (currTime >= promoStartTime && currTime < promoEndTime) {
          //Live message
          newPromoSuffix = currentShow.promo.dayOfGame.duringGame;
          promoURL = currentShow.promo.liveURL;

          if(!promoLaunched) {
            promoLaunched = true;
            document.querySelector('.main').insertBefore(document.querySelector('.promo'), document.querySelector('.map'));
          }
        } else if (currTime >= promoEndTime) {
          document.querySelector('.promo').addClass('promo--hide');
          clearInterval(promoInterval);
        }

        if(promoSuffix !== newPromoSuffix || promoSize !== newPromoSize) {
          promoSuffix = newPromoSuffix;
          promoSize = newPromoSize;

          document.querySelector('.promo__image').innerHTML = `<a href="${promoURL}" target="_blank" onclick="gtag('event', 'click', {'event_category': 'sign_up', 'event_label': 'sign_up_now'});"><img class="lazyload" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/promos/${currentShow.promo.name}_${promoSize}_${promoSuffix}" alt=""></a>`;
        }
      };

      onImageResize();
      window.addEventListener('resize', onImageResize);

      const promoInterval = setInterval(promoTimer, 15000);
  } else {
    document.querySelector('.promo').classList.add('promo--hide');
  }
}
