export function youtubeplaylist(currentShow) {
    document.querySelector("#youtubeThumbnail_1").src = "https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/youtubeplaylist/" + currentShow.youtube_playlist.thumbnail_one;
    document.querySelector("#youtubeThumbnail_2").src = "https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/youtubeplaylist/" + currentShow.youtube_playlist.thumbnail_two;
    document.querySelector("#youtubeThumbnail_3").src = "https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/youtubeplaylist/" + currentShow.youtube_playlist.thumbnail_three;
    document.querySelector("#youtubelink_1").href = currentShow.youtube_playlist.url_one;
    document.querySelector("#youtubelink_2").href = currentShow.youtube_playlist.url_two;
    document.querySelector("#youtubelink_3").href = currentShow.youtube_playlist.url_three;

    document.querySelector("#youtube_copy_1").innerHTML = currentShow.youtube_playlist.title_one;
    document.querySelector("#youtube_copy_2").innerHTML = currentShow.youtube_playlist.title_two;
    document.querySelector("#youtube_copy_3").innerHTML = currentShow.youtube_playlist.title_three;
}



