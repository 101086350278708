export function schedule(currentShow) {
    if (currentShow.scheduleVisible) {
        const currentTime = dayjs();

        let scheduleDates = '',
            scheduleDays = '';

        for (let i in currentShow.schedule) {
            const index = parseInt(i) + 1,
                date = dayjs(currentShow.schedule[i].date),
                formattedDate = date.format("dddd, MMM D").replace('Sep ', 'Sept ');

            const scheduleDatesWidth = (currentShow.schedule.length < 2) ? 'small-12' : 'small-6';
            scheduleDates += `<div class="${scheduleDatesWidth} cell schedule__date" data-id="${index}" data-date="${date.format('YYYY-MM-DD')}"><div class="schedule__date-inner">${formattedDate}</div></div>`;

            const scheduleDaysWidth = (currentShow.schedule.length < 2) ? 'small-12' : 'small-12 medium-6';
            scheduleDays += `<div class="${scheduleDaysWidth} cell schedule__day" data-id="${index}" data-date="${date.format('YYYY-MM-DD')}">`;

            // Schedule and icon
            for (let j in currentShow.schedule[i].items) {
                scheduleDays += `<!--<div class="schedule__showlogos"><img  src="./assets/img/showlogo/showlogo-${currentShow.schedule[i].items[j].showLogo}.png"  alt="show Logo"></div><div class="schedule__day-item"><p class="schedule__title">${currentShow.schedule[i].items[j].title}</p><p class="schedule__copy">${currentShow.schedule[i].items[j].copy}<br/>${currentShow.schedule[i].items[j].time}</p> </div>-->`;
                scheduleDays += `<div class="schedule__showlogos"><img  src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/showlogo/showlogo-${currentShow.schedule[i].items[j].showLogo}.png"  alt="show Logo"></div><div class="schedule__day-item"><p class="schedule__title">${currentShow.schedule[i].items[j].title}</p><p class="schedule__copy">${currentShow.schedule[i].items[j].copy}<br/>${currentShow.schedule[i].items[j].time}</p> </div>`;
            }

            scheduleDays += '</div>';
        }

        document.querySelector('.schedule__content-grid').innerHTML = `${scheduleDates}${scheduleDays}`;

        let winWidth = null;

        const days = document.querySelectorAll('.schedule__day'),
            dates = document.querySelectorAll('.schedule__date');

        const toggleDays = () => {
            const newWinWidth = window.innerWidth,
                formattedTime = currentTime.format('YYYY-MM-DD');

            if (winWidth !== newWinWidth) {
                winWidth = newWinWidth;

                if (winWidth >= 640) {
                    days.forEach((el) => el.style.display = 'block');
                } else {
                    let isDateActive = false,
                        isDayVisible = false;

                    dates.forEach((el) => {
                        el.classList.remove('schedule__date--active');
                        if (el.dataset.date === formattedTime) {
                            el.classList.add('schedule__date--active');
                            isDateActive = true;
                        }
                    });

                    days.forEach((el) => {
                        el.style.display = 'none';
                        if (el.dataset.date === formattedTime) {
                            el.style.display = 'block';
                            isDayVisible = true;
                        }
                    });

                    if (!isDateActive) document.querySelector('.schedule__date[data-id="1"]').classList.add('schedule__date--active');
                    if (!isDayVisible) document.querySelector('.schedule__day[data-id="1"]').style.display = 'block';
                }
            }
        }


        toggleDays();
        window.addEventListener('resize', toggleDays);

        if (currentShow.schedule.length > 1) {
            dates.forEach(function (el) {
                el.addEventListener('click', function (evt) {
                    const date = evt.target.parentNode.dataset.date;

                    if (window.innerWidth < 640 && date !== undefined) {
                        dates.forEach((el) => el.classList.remove('schedule__date--active'));
                        days.forEach((el) => el.style.display = 'none');

                        evt.target.parentNode.classList.add('schedule__date--active');
                        document.querySelector(`.schedule__day[data-date="${date}"]`).style.display = 'block';
                    }
                });
            });
        }

        if (currentShow.scheduleDownload !== '') {
            document.querySelector('.schedule__download-btn').innerHTML = `<a href="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/downloads/${currentShow.scheduleDownload}" target="_blank"><img class="lazyload download-btn" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/download-btn.svg" alt=""></a>`;
        }
    } else {
        document.querySelector('.schedule').classList.add('schedule--hide');
    }
}
