export function instagram(currentShow) {

    if (currentShow.instagramVisible === false) {
        document.querySelector(".instagram").style.display = "none";
    } else {
        document.querySelector(".firstWord").innerHTML = currentShow.instagram.city;
        document.querySelector(".leftInstagram img").src = "https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/instagram/" + currentShow.instagram.image;
        // document.querySelector(".leftInstagram img").src = "assets/img/instagram/" + currentShow.instagram.image;
        document.querySelector(".insCopy").innerHTML = currentShow.instagram.copy;
        document.querySelector(".leftInstagram a").href = currentShow.instagram.url;
    }
}

