export function sponsors(currentShow) {
    let newSponsorSize = '';
    let sponsorSize = '';


    if (currentShow.sponsorsVisible) {
        const onImageResize = () => {
            const imgWidth = document.querySelector('.sponsors__image').clientWidth;

            if (imgWidth >= 971) {
                newSponsorSize = '1280px_image';
            } else if (imgWidth >= 729) {
                newSponsorSize = '970px_image';
            } else if (imgWidth >= 640) {
                newSponsorSize = '728px_image';
            } else {
                newSponsorSize = '300px_image';
            }

            document.querySelector('.sponsors__image').innerHTML = `<img class="lazyload" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/sponsors/${currentShow.sponsors[newSponsorSize]}" alt="">`;
        }

        onImageResize();
        window.onresize = onImageResize;

    } else {
        document.querySelector('.sponsors').classList.add('sponsors--hide');
    }
}