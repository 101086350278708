export function matchup(currentShow) {
    const homeTeam = currentShow.homeTeam.replace(/ /g, '').toLowerCase(),
        awayTeam = currentShow.awayTeam.replace(/ /g, '').toLowerCase(),
        startDate = dayjs.tz(currentShow.year + '-' + currentShow.month + '-' + currentShow.date + ' ' + currentShow.startTime, "America/New_York"),
        startTime = startDate.format("h") + startDate.format("a"),
        endDate = dayjs.tz(currentShow.year + '-' + currentShow.month + '-' + currentShow.date + ' ' + currentShow.endTime, "America/New_York"),
        endTime = endDate.format("h") + endDate.format("a"),
        eventDay = startDate.format("dddd");

    const awayTeamRanking = (currentShow.awayTeamRanking !== '') ? `<div class="matchup__ranking matchup__ranking--away">${currentShow.awayTeamRanking}</div>` : '',
        homeTeamRanking = (currentShow.homeTeamRanking !== '') ? `<div class="matchup__ranking matchup__ranking--home">${currentShow.homeTeamRanking}</div>` : '';

    document.querySelector('.matchup__schools').innerHTML = `<img class="lazyload matchup__school" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/schools/school_${awayTeam}.png" alt="">${awayTeamRanking}<img class="lazyload matchup__school" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/schools/school_${homeTeam}.png" alt="">${homeTeamRanking}`;

    const monthDay = startDate.format("MMMM D").replace('Sep ', 'Sept '),
        note = (currentShow.location.note !== '') ? `<p class="matchup__note">${currentShow.location.note}</p>` : '';

    document.querySelector('.matchup__info').innerHTML = `<h2 class="matchup__heading">LIVE FROM ${currentShow.school}</h2><p class="matchup__location">${currentShow.stadiumName}</p><p class="matchup__date-time">${eventDay}, ${monthDay}  <span class="erase"> | </span> <br class="mobile-break" > ${startTime.replace('am', ' AM ')} - ${endTime.replace('pm', ' PM ')} ET  ${currentShow.location.additionalTime}</p>${note}`;
}
