export function livestream(currentShow) {
    if (currentShow.liveStreamVisible) {
        document.querySelector('.livestream__image-container').innerHTML = `<img class="lazyload livestream__image" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/livestream/${currentShow.liveStream.preImage}" alt="">`;

        let liveStreamStartTime = dayjs.tz(currentShow.liveStream.startTime, "America/New_York");
        let liveStreamEndTime = dayjs.tz(currentShow.liveStream.endTime, "America/New_York");

        const liveStreamTimer = () => {
            const currTime = dayjs();


            document.querySelector('.livestream-href').href = currentShow.liveStream.preURL;


            // Update logic
            document.querySelector(".livestream__grid").style.pointerEvents = "none";


            if (currTime >= liveStreamStartTime) {
                document.querySelector('.livestream__image-container').innerHTML = `<img class="lazyload livestream__image" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/livestream/${currentShow.liveStream.liveImage}" alt="">`;
                document.querySelector('.livestream-href').href = currentShow.liveStream.liveURL;

                document.querySelector(".livestream__grid").style.pointerEvents = "auto";
            }


            if (currTime >= liveStreamEndTime) {
                if (!currentShow.liveStream.replay) document.querySelector('.livestream').classList.add('livestream--hide');
                clearInterval(liveStreamInterval);
            }
        };
        const liveStreamInterval = setInterval(liveStreamTimer, 15000);
        liveStreamTimer();
    } else {
        document.querySelector('.livestream').classList.add('livestream--hide');
    }
}

