export function faq(currentShow) {
    let items1 = '',
        items2 = '';

    for (let i = 0; i < 3; i++) {
        items1 += `<div class="faq__item"><p class="faq__question">${currentShow.faq[i].question}</p><p class="faq__copy">${currentShow.faq[i].copy}</p></div>`;
    }

    const faq1 = `<div class="small-12 medium-6 cell faq__cell-1">${items1}</div>`;

    for (let i = 3; i < currentShow.faq.length; i++) {
        items2 += `<div class="faq__item"><p class="faq__question">${currentShow.faq[i].question}</p><p class="faq__copy">${currentShow.faq[i].copy}</p></div>`;
    }

    const faq2 = `<div class="small-12 medium-6 cell faq__cell-2">${items2}</div>`;

    document.querySelector('.faq__content-grid').innerHTML = `${faq1}${faq2}`;
}
