export function map(currentShow) {
  if(currentShow.map !== '') {
    document.querySelector('.map__container').innerHTML = `<img class="lazyload map__image" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/maps/${currentShow.map}" alt="">`;
    document.querySelector('.map__heading').innerText = currentShow.location.name;
    document.querySelector('.map__address').innerText = currentShow.location.address;


    if(currentShow.mapDownload !== '') {
      const downloadBtns = document.querySelectorAll('.map__download-btn');

      downloadBtns.forEach((el) => {
        // el.innerHTML = `<a href="./assets/downloads/${currentShow.mapDownload}" target="_blank"><img class="lazyload download-btn" data-src="https://secure.espncdn.com/promotions/bsa/2022/apps/cgd/assets/img/download-btn.svg" alt=""></a>`;
        el.innerHTML = `<a href="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/downloads/${currentShow.mapDownload}" target="_blank"><img class="lazyload download-btn" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/download-btn.svg" alt=""></a>`;
      });
    }

    let mapInfoContent = '';

    for (let i in currentShow.location.info) {
      mapInfoContent += `<div class="map__info-item"><p class="map__title">${currentShow.location.info[i].title}</p><p class="map__copy">${currentShow.location.info[i].copy}</p></div>`;
    }

    document.querySelector('.map__info-content').innerHTML = mapInfoContent;
  } else {
    document.querySelector('.map').classList.add('map--hide');
  }
}
