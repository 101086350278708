import 'lazysizes';
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin.js';
import {matchup} from './components/matchup';
import {map} from './components/map';
import {schedule} from './components/schedule';
import {gallery} from './components/gallery';
import {faq} from './components/faq';
import {livestream} from './components/livestream';
import {promo} from './components/promo';
import {video} from './components/video';
import {animateHeads} from './components/animateheads';
import {cerealPolls} from "./components/cerealPolls";
import {instagram} from "./components/instagram";
import {youtubeplaylist} from "./components/youtubeplaylist";
import {sponsors} from "./components/sponsors";


gsap.registerPlugin(ScrollToPlugin);


document.addEventListener('DOMContentLoaded', () => {
    const cachebuster = Math.round(new Date().getTime() / 1000);
    fetch(`https://secure.espncdn.com/promotions/ptsa/2024/apps/cgd/assets/json/data.json?cb=${cachebuster}`)
    // fetch(`./assets/json/data.json?cb=${cachebuster}`)
        .then((response) => response.json())
        .then((data) => getCurrentShow(data))
        .catch((err) => console.log('error: ' + err));



    //Navbar link
    document.querySelector('.navbar__link').addEventListener('click', (evt) => {
        evt.preventDefault();
        gsap.to(window, {duration: .5, scrollTo: 0});
    });

    //Poll Query String
    const urlParams = new URLSearchParams(window.location.search),
        poll = urlParams.get('poll');

    if (poll) {
        setTimeout(() => {
            gsap.to(window, {duration: 1, scrollTo: {y: '.poll', offsetY: 64}});
        }, 1000);
    }
});

function getCurrentShow(data) {
    const currentTime = dayjs();

    let closest = null,
        currentShow = null;

    for (let i in data) {
        const show = data[i],
            dateString = `${show.year}-${show.month}-${show.date} 23:59`,
            showDate = dayjs.tz(dateString, "America/New_York");

        //Determine the next show
        if (showDate >= currentTime && (showDate < closest || closest === null)) {
            closest = showDate;
            currentShow = show;
        }
    }

    //Polls Logic Runs regardless
    if (currentShow !== null) {
        //Update site primary and secondary colors
        document.documentElement.style.setProperty("--primaryColor", currentShow.homeTeamPrimaryColor);
        document.documentElement.style.setProperty("--secondaryColor", currentShow.homeTeamSecondaryColor);


        //Init modules
        matchup(currentShow);
        sponsors(currentShow);
        map(currentShow);
        livestream(currentShow);
        schedule(currentShow);
        gallery(currentShow);
        promo(currentShow);
        video(currentShow);
        faq(currentShow);
        animateHeads(currentShow);
        instagram(currentShow);
        youtubeplaylist(currentShow);

    } else {
        document.querySelector('.main__gallery').innerHTML = '<img class="lazyload" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/gameday_default.jpg" alt="">';
        document.querySelector(".matchup__info").innerHTML = '<h2 class="matchup__heading">' + data[0].defaultMessage + '</h2>';
        document.querySelector(".section.poll").style.display = 'none';
        document.querySelector(".main__image").style.display = 'none';
        // document.querySelector(".video").style.display = 'none';
        document.querySelector(".instagram").style.display = 'none';
        // document.querySelector(".signs").style.display = 'none';
        // document.querySelector(".faq").style.display = 'none';
        document.querySelector('body').classList.add('no-show');

        // Default Messages that needs to be pulled in
        video(data[0]);
        animateHeads(data[0]);
        youtubeplaylist(data[0])

    }
}