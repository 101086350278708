export function gallery(currentShow) {
    let gallery = '';

    for (let i in currentShow.gallery) {
        const index = parseInt(i) + 1;
        gallery += `<img class="lazyload img${index}" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/gallery/${currentShow.gallery[i]}" alt="">`
    }

    document.querySelector('.main__gallery').innerHTML = gallery;
    document.querySelector('.main__image').innerHTML = `<img class="lazyload" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/gallery/${currentShow.gallery[2]}" alt="">`;
}

