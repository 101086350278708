import {gsap} from 'gsap';

export function animateHeads(currentShow) {
    let heads = '',
        headsArr = [];

    const randomHeads = getRandomHeads(currentShow.randomHeads, 2);

    //Random Head 1
    const randomHead1 = `<img class="lazyload ${randomHeads[0]}" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/heads/${randomHeads[0]}.png" alt="">`,
        randomHead2 = `<img class="lazyload ${randomHeads[1]}" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/heads/${randomHeads[1]}.png" alt="">`;

    heads += randomHead1;
    headsArr.push(`.${randomHeads[0]}`);

    for (let i in currentShow.heads) {
        const head = `<img class="lazyload ${currentShow.heads[i]}" data-src="https://secure.espncdn.com/promotions/bsa/2024/apps/cgd/assets/img/heads/${currentShow.heads[i]}.png" alt="">`;
        heads += head;
        headsArr.push(`.${currentShow.heads[i]}`);
    }

    //Random Head 2
    heads += randomHead2;
    headsArr.push(`.${randomHeads[1]}`);

    document.querySelector('.signs__heads').innerHTML = heads;

    for (let i = 0; i < headsArr.length; i++) {
        gsap.set(headsArr[i], {rotation: getRandomAngle(-10, 10)});
        gsap.to(headsArr[i], gsap.utils.random(1, 2), {
            delay: gsap.utils.random(0, 1),
            x: '+=' + gsap.utils.random(-5, 5) + 'px',
            y: '+=' + gsap.utils.random(10, 20) + 'px',
            yoyo: true,
            repeat: -1,
            ease: 'sine.inOut'
        });
    }
}

function getRandomHeads(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
}

function getRandomAngle(min, max) {
    return Math.floor(Math.random() * (1 + max - min) + min);
}
